import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthHeader from '../component/MainHeader/components dashboard/AuthHeader';
import AuthLogo from '../component/MainHeader/components dashboard/AuthLogo';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

const SignUp = () => {
  const { addToast } = useToasts();

  const history = useHistory();
  const [state, setState] = useState('');
  const [countries, setCountries] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [formErrors, setFormErrors] = useState({});
  console.log(formErrors, 'formErrors');
  const [isSubmit, setIsSubmit] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(inputValue, 'inputValue');
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors, isSubmit]);

  //validate
  const validate = (values) => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Email is Required !';
    }
    if (!values.password) {
      errors.password = 'Password is Required !';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is Required!';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match!';
    }
    if (!values.firstName) {
      errors.firstName = 'firstName is Required !';
    }
    if (!values.lastname) {
      errors.lastName = 'lastName is Required !';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone Number is Required !';
    }
    if (!values.city) {
      errors.city = 'City is Required !';
    }
    if (!values.state) {
      errors.state = 'State  is Required !';
    }
    if (!values.country) {
      errors.country = 'Country  is Required !';
    }
    if (!values.zcode) {
      errors.zcode = 'Zip Code Number is Required !';
    }

    if (!values.address) {
      errors.address = 'Address field is Required !';
    }
    return errors;
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setFormErrors(validate(inputValue));
    // setIsSubmit(true);

    if (
      inputValue.password === inputValue.confirmPassword &&
      inputValue.firstName &&
      inputValue.lastname &&
      inputValue.password &&
      inputValue.confirmPassword &&
      inputValue.email &&
      inputValue.phoneNumber &&
      inputValue.address &&
      inputValue.city &&
      inputValue.state &&
      inputValue.country &&
      inputValue.zcode
    ) {
      const newRecord = { ...inputValue };

      console.log(apiUrl, 'process.env.NEXT_PUBLIC_API_URL');

      // With axios:
      let data = JSON.stringify(newRecord);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/register`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response, 'status'));

          history.push('/authentication-signin.html');
        })
        .catch((error) => {
          console.log(error.response, 'response');
          addToast(error?.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });

      // fetch(`${apiUrl}/api/register`, {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(newRecord),
      // }).then((result) => {
      //   result.json().then((resp) => {
      //     console.log(resp, 'resp');
      //     history.push('/authentication-signin.html');
      //   });
      // });
    } else {
      console.log('data Error');
    }
  };

  //country drop down
  const fetchData = async () => {
    const countryData = await fetch(`${apiUrl}/api/countries`);
    const countryJson = await countryData.json();
    console.log(countryJson, 'countryData');

    setCountries(countryJson?.countries);
  };
  useEffect(() => {
    fetchData();
  }, []);

  //state data
  const stateFetchData = async () => {
    const stateData = await fetch(`${apiUrl}/api/states`);
    const stateJson = await stateData.json();
    setState(stateJson?.states);
  };
  useEffect(() => {
    stateFetchData();
  }, []);

  return (
    <>
      <section className='main signup'>
        <AuthHeader />
        <div className='right-side text-center'>
          <AuthLogo />
          <form onSubmit={submitForm}>
            <div className='row'>
              <div className='col-lg-6 mb-2'>
                <input
                  type='text'
                  name='firstName'
                  placeholder='First Name'
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  value={inputValue.firstName}
                  onChange={inputHandler}
                />
                {!inputValue.firstName && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.firstName}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <input
                  type='text'
                  name='lastname'
                  placeholder='Last Name'
                  className='form-control ms-0 mb-2 mb-md-0'
                  value={inputValue.lastname}
                  onChange={inputHandler}
                />
                {!inputValue.lastname && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.lastname}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <input
                  type='password'
                  name='password'
                  placeholder='Password'
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  value={inputValue.password}
                  onChange={inputHandler}
                />
                {!inputValue.password && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.password}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <input
                  type='password'
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  className='form-control ms-0 mb-2 mb-md-0'
                  value={inputValue.confirmPassword}
                  onChange={inputHandler}
                />
                {(!inputValue.confirmPassword ||
                  inputValue.password !== inputValue.confirmPassword) && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.confirmPassword}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  value={inputValue.email}
                  onChange={inputHandler}
                />
                {!inputValue.email && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.email}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <input
                  type='number'
                  name='phoneNumber'
                  placeholder='Phone number'
                  maxLength={10}
                  className='form-control ms-0 mb-2 mb-md-0'
                  value={inputValue.phoneNumber}
                  onChange={inputHandler}
                />
                {!inputValue.phoneNumber && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.phoneNumber}
                  </p>
                )}
              </div>
              <div className='col-lg-12 mb-2'>
                <textarea
                  className='form-control me-0 mb-2 me-0 mb-md-0'
                  rows='2'
                  placeholder='Address'
                  name='address'
                  value={inputValue.address}
                  onChange={inputHandler}
                ></textarea>
                {!inputValue.address && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.address}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                {/* <select
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  id='state'
                  name='city'
                  onChange={inputHandler}
                >
                  <option>City</option>
                  {state &&
                    state.map((items, key) => {
                      return (
                        <>
                          <option key={key} value={items.city}>
                            {items.city}
                          </option>
                        </>
                      );
                    })} 
                  </select>*/}
                <input
                  type='text'
                  name='city'
                  placeholder='City'
                  className='form-control ms-0 mb-2 mb-md-0'
                  value={inputValue.city}
                  onChange={inputHandler}
                />
                {!inputValue.city && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.city}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <select
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  id='state'
                  name='state'
                  onChange={inputHandler}
                >
                  <option>State</option>
                  {state &&
                    state.map((items, key) => {
                      return (
                        <>
                          <option key={key} value={items?.vlongname_state}>
                            {items?.vlongname_state}
                          </option>
                        </>
                      );
                    })}
                </select>
                {!inputValue.state && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.state}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                <select
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  id='countries'
                  name='country'
                  onChange={inputHandler}
                >
                  <option>Country</option>
                  {countries &&
                    countries.map((item, key) => {
                      return (
                        <>
                          <option key={key} value={item?.vlongname}>
                            {item?.vlongname}
                          </option>
                        </>
                      );
                    })}
                </select>
                {!inputValue.country && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.country}
                  </p>
                )}
              </div>
              <div className='col-lg-6 mb-2'>
                {/* <select
                  className='form-control me-0 mb-2 me-md-2 mb-md-0'
                  id='state'
                  name='zcode'
                  onChange={inputHandler}
                >
                  <option>Zip Code</option>
                  {state &&
                    state.map((items, key) => {
                      return (
                        <>
                          <option key={key} value={items.pincode}>
                            {items.pincode}
                          </option>
                        </>
                      );
                    })}
                </select> */}
                <input
                  type='text'
                  name='zcode'
                  placeholder='Zip Code'
                  className='form-control ms-0 mb-2 mb-md-0'
                  value={inputValue.zcode}
                  onChange={inputHandler}
                />
                {!inputValue.zcode && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    {formErrors.zcode}
                  </p>
                )}
              </div>
            </div>
            <button className='btn btn-primary mt-2 ' type='submit'>
              SIGN UP
            </button>
            <p className='mt-4'>
              Already have an account{' '}
              <Link to='/authentication-signin.html'>Sign In</Link>
            </p>
          </form>
        </div>
      </section>
    </>
  );
};

export default SignUp;
