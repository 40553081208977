import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Footer from '../component/subscription/Footer';
import Header from '../component/subscription/Header';

const PaymentSuccessful = () => {
  const currentUrl = window.location.href;

  // Parse the URL to extract query parameters
  const urlParams = new URLSearchParams(currentUrl.split('?')[1]);

  // Get the value of the "id" query parameter
  const id = urlParams.get('id');
  console.log(id, 'id');

  return (
    <>
      <Header />
      <section className='custom-margin-top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div className='card payment-successful-card text-center align-items-center mt-5'>
                <img
                  src='assets/images/check.png'
                  className='img-fluid mx-auto'
                  width='50'
                  alt='check'
                />
                <h2 className='white-text mt-3'>Payment Received</h2>
                <p className='white-text mt-3'>
                  Congratulations ! <br />
                  You have been successfully subscribed Premium Plan.
                </p>
                <Link to={`dashboard.html`} className='btn btn-primary mt-3'>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PaymentSuccessful;
