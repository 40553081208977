import React from "react";

const Error = () => {
    return (
        <>
            <section className="response-info">
                <span className="status-code">404</span>
                <span className="status-reason">Not Found</span>
                <section className="contact-info">
                    "Please forward this error screen to demo.theclosedoor.com"
                    <a href="mailto:webmaster@demo.theclosedoor.com?subject=Error message [404] (none)...wrx-user/dashboarddcx.html port 443 on Tuesday, 28-Dec-2021 06:38:51 UTC ">
                        WebMaster
                    </a>
                </section>
                <p className="reason-text">
                    <p class="reason-text">The server can not find the requested page:</p>{" "}
                </p>
            </section>

            <section className="additional-info">
                <div className="container">
                    <div className="additional-info-items">
                        <ul>
                            <li>
                                <img
                                    src="/img-sys/server_misconfigured.png"
                                    alt=""
                                    className="info-image"
                                />
                                <div className="info-heading">
                                    "demo.theclosedoor.com/Trustwrx-user/dashboardcx.html (port
                                    443)"
                                </div>
                            </li>
                            <li className="info-server"></li>
                        </ul>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <a
                        href="http://cpanel.com/?utm_source=cpanelwhm&utm_medium=cplogo&utm_content=logolink&utm_campaign=404referral"
                        target="cpanel"
                        title="cPanel"
                    >
                        <img
                            src="/img-svs/powered_by_cpanel.svg"
                            height="20"
                            alt="cPanel"
                        />
                    </a>
                    <div className="copyright">
                        <a
                            href="http://cpanel.com/?utm_source=cpanelwhm&utm_medium=cplogo&utm_content=logolink&utm_campaign=404referral"
                            target="cpanel"
                            title="cPanel"
                        ></a>

                        <a
                            href="http://cpanel.com/?utm_source=cpanelwhm&amp;utm_medium=cplogo&amp;utm_content=logolink&amp;utm_campaign=404referral"
                            target="cpanel"
                            title="cPanel, Inc."
                        >
                            Copyright © 2020 cPanel, L.L.C.
                        </a>
                        <a href="https://go.cpanel.net/privacy"></a>
                        <br />
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Error;
