import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

const Devic = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('access_token');
  const [data, setData] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/devices`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setData(response?.data?.data);
      })
      .catch((error) => {
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log(error);
      });
  }, []);
  return (
    <div className='page-wrapper'>
      <div className='breadcrunbs'>
        <h6 className='blue-text semibold-text mb-0'>Devices</h6>
      </div>
      <div className='page-content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card p-3 m-0'>
              <div className='table-responsive'>
                {' '}
                {data && data?.length > 0 ? (
                  <table
                    id='example'
                    className='table'
                    style={{ width: '100 %' }}
                  >
                    <thead>
                      <tr>
                        <th>Device ID</th>
                        <th>Device Name</th>
                        <th>OS Version</th>
                        <th>Connected Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item) => (
                        <tr key={item.id}>
                          <td>#{item.device}</td>
                          <td>{item.device_title}</td>
                          <td>{item.os_version}</td>
                          <td>{item.connection_date}</td>
                          <td>
                            <span
                              className={
                                item.status === 'Active'
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              <span className='dot position-relative'></span>
                              {item.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                      <td>#456325</td>
                      <td>Desktop-TP-VC-3214</td>
                      <td>Windows 10</td>
                      <td>September 10 , 2021</td>
                      <td>
                        <span className='text-danger ms-2 d-block'>
                          <span className='dot position-relative'></span>
                          Inactive
                        </span>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                ) : (
                  <p>No devices available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devic;
