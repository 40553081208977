import React, { useEffect, useState } from 'react';
import ThreatMon from '../component/MainHeader/components dashboard/ThreatMon';
import MainFooter from '../component/MainHeader/MainFooter';
import MainHeader from '../component/MainHeader/MainHeader';
// import MainHeader from "../component/MainHeader/MainHeader";
import MainNav from '../component/MainHeader/MainNav';

const ThreatMonitor = () => {
  const [toggled, setToggled] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [proFilePic, setProFilePic] = useState(
    localStorage.getItem('profileimg')
  );
  useEffect(() => {
    setProFilePic(localStorage.getItem('profileimg'));
  }, [localStorage.getItem('profileimg')]);
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('user') || '{}'));
  }, [localStorage.getItem('user')]);
  return (
    <div className={`wrapper ${toggled ? 'toggled' : ''}`}>
      <MainHeader
        handleToggle={() => {
          setToggled(!toggled);
        }}
        user_Data={userData}
        proFilePic={proFilePic}
      />
      <MainNav
        handleToggle={() => {
          setToggled(!toggled);
        }}
      />
      <ThreatMon />
      <MainFooter />
    </div>
  );
};

export default ThreatMonitor;
