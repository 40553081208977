import React from "react";

const ThreatMon = () => {
    return (
        <div className="page-wrapper">
            <div className="breadcrunbs">
                <h6 className="blue-text semibold-text mb-0">Threat Monitor</h6>
            </div>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12"></div>
                </div>
            </div>
        </div>
    );
};

export default ThreatMon;
