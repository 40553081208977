import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const MainHeader = ({ handleToggle, user_Data, proFilePic }) => {
  const history = useHistory();
  const [picture, setPicture] = useState('');
  console.log(picture, 'picture');
  // const [user_Data, setUserData] = useState(
  //   JSON.parse(localStorage.getItem('user') || '{}')
  // );
  // console.log(user_Data, 'user_Data');
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');

    history.push('/authentication-signin.html');
  };

  // useEffect(() => {
  //   setUserData(JSON.parse(localStorage.getItem('user') || '{}'));
  // }, [localStorage.getItem('user')]);
  useEffect(() => {
    setPicture(localStorage.getItem('profileimg'));
  }, []);

  return (
    <>
      <header>
        <div className='topbar d-flex align-items-center'>
          <nav className='navbar navbar-expand'>
            <div className='mobile-toggle-menu' onClick={handleToggle}>
              <i className='bx bx-menu'></i>
            </div>

            <div className='top-menu ms-auto'>
              {/* <div className="dropdown">
                                <button
                                    className="btn btn-secondary dropdown-toggle btn-search"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="bx bx-search"></i>
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <Link className="dropdown-item" to="#">
                                            <input
                                                type="text"
                                                name=""
                                                className="form-control"
                                                placeholder="Search Here"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
            </div>
            <div className='user-box dropdown border-0 ms-0'>
              <a
                className='
                  d-flex
                  align-items-center
                  nav-link
                  dropdown-toggle dropdown-toggle-nocaret
                '
                href='#'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <img
                  // src="assets/images/avatars/avatar-2.png"
                  src={proFilePic}
                  className='user-img'
                  alt=''
                />
                <div className='user-info ps-3'>
                  <p className='user-name mb-0'>
                    {user_Data?.first_name}
                    {'   '}
                    {user_Data?.last_name}
                  </p>
                </div>
              </a>
              <ul className='dropdown-menu dropdown-menu-end'>
                <li>
                  <Link
                    className='dropdown-item'
                    // href="authentication-signin.html"
                    onClick={logout}
                  >
                    <i className='bx bx-log-out-circle'></i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default MainHeader;
