import React from 'react'
import { Link } from 'react-router-dom'

const AuthLogo = () => {
    return (
        <>
            <Link to="#" className="mb-4 d-block">
                <img
                    src="assets/images/logo.png"
                    className="img-fluid"
                    alt="logo"
                />
            </Link>
        </>
    )
}

export default AuthLogo;
