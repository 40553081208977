import React from "react";
import { Link } from "react-router-dom";

const TrustUser = () => {
    return (
        <>
            <section className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mt-3">
                            <Link to="authentication-signup.html">
                                <img src="assets/images/small-logo.png" className="img-fluid" alt="smallLogo" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrustUser;
