import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthHeader from '../component/MainHeader/components dashboard/AuthHeader';
import AuthLogo from '../component/MainHeader/components dashboard/AuthLogo';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
const AuthSignIn = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inputValue, setInputValue] = useState([]);
  const [loginError, setLoginError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  //   addToast('Hello, this is a toast!', { appearance: 'success' });

  // useEffect(() => {
  //     fetch("https://61cc1144198df60017aebe5f.mockapi.io/singup").then(
  //         (result) => {
  //             result.json().then((resp) => {
  //                 setInputValue(resp);
  //             });
  //         }
  //     );
  // }, []);

  // {
  //     inputValue && inputValue.map((signin) => {
  //         if (!signin.email === email) {
  //             // return <p>Email is wrong</p>
  //             console.log("Email is wrong")
  //         }
  //         if (!signin.password === email) {
  //             console.log("password is wrong")
  //             // return <p>password is wrong</p>
  //         }
  //     })
  // }

  const login = () => {
    // inputValue.map((user) => {
    if (email && password) {
      // const user_data = {
      //     id: user.id,
      //     firstName: user.firstName,
      //     lastName: user.lastName,
      //     email: user.email,
      //     phoneNumber: user.phoneNumber,
      //     address: user.address,
      //     city: user.city,
      //     state: user.state,
      //     country: user.country,
      //     zcode: user.zcode
      // }

      let data = JSON.stringify({
        email: email,
        password: password,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/login`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
          });
          localStorage.setItem('access_token', response?.data?.access_token);

          localStorage.setItem(
            'user',
            JSON.stringify(response?.data?.customer)
          );
          localStorage.setItem(
            'profileimg',
            response?.data?.customer?.profile_pic?.url
          );
          if (response?.data?.customer?.subscriptions?.length > 0) {
            history.push('/dashboard.html');
          } else {
            history.push('/subscription.html');
          }
        })
        .catch((error) => {
          addToast(error?.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log(error);
        });
    } else {
      setLoginError('Please enter valid email and password');
    }
    // })
  };

  return (
    <>
      {/* <!--wrapper--> */}
      <section className='main'>
        <AuthHeader />
        <div className='right-side text-center'>
          <AuthLogo />
          <form>
            <div className='mb-3'>
              <input
                type='text'
                name='email'
                placeholder='Email'
                className='form-control'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='mb-3'>
              <input
                type='password'
                name='password'
                placeholder='Password'
                className='form-control'
                autoComplete='Off'
                onChange={(e) => setPassword(e.target.value)}
              />
              <p style={{ color: 'red', textAlign: 'left' }}>{loginError}</p>
              <Link
                to='/forgotpassword.html'
                className='float-end gray-text mt-2'
              >
                Forgot Password?
              </Link>
              <div className='clearfix'></div>
            </div>
            <Link to='#' className='btn btn-primary' onClick={login}>
              SIGN IN
            </Link>
          </form>
          <p className='mt-4'>
            Don't have an account{' '}
            <Link to='/authentication-signup.html'>Sign Up</Link>
          </p>
          <ul className='list-inline list-unstyled social-media-list'>
            <li className='list-inline-item'>
              <Link to='#'>
                <img
                  src='assets/images/facebook.png'
                  width='35'
                  height='35'
                  className='img-fluid rounded-circle'
                  alt='facebook'
                />
              </Link>
            </li>
            <li className='list-inline-item'>
              <Link to='#'>
                <img
                  src='assets/images/google-plus.png'
                  width='35'
                  height='35'
                  className='img-fluid rounded-circle'
                  alt='google+'
                />
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default AuthSignIn;
