import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const Header = () => {
  const [picture, setPicture] = useState('');
  const history = useHistory();
  let userData = {};
  userData = JSON.parse(localStorage.getItem('user'));
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');

    history.push('/authentication-signin.html');
  };
  useEffect(() => {
    // setPicture(localStorage.getItem('recent-image'));
    setPicture(localStorage.getItem('profileimg'));
  }, []);
  return (
    <>
      <header>
        <div className='topbar topbar-custom d-flex align-items-center'>
          <nav className='navbar navbar-expand-lg'>
            <div className='container-fluid'>
              <Link to='#'>
                <img
                  src='assets/images/small-logo.png'
                  className='img-fluid logo'
                  width='150'
                  alt='logo'
                />
              </Link>
              <button
                className='navbar-toggler ms-auto'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
                <span className='navbar-toggler-icon'></span>
                <span className='navbar-toggler-icon'></span>
              </button>
              <div
                className='collapse navbar-collapse'
                id='navbarSupportedContent'
              >
                <ul className='list-unstyled list-inline ms-auto mb-0'>
                  <li className='list-inline-item me-4'>
                    <Link to='index.html'>Home</Link>
                  </li>
                  <li className='list-inline-item me-2 active'>
                    <Link to='subscription.html'>Subscription</Link>
                  </li>
                </ul>
              </div>
              <a
                className='d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret user-info-box'
                href='#'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <img
                  // src="assets/images/avatars/avatar-2.png"
                  src={picture}
                  className='user-img'
                  alt=''
                />
                <div className='user-info ps-3'>
                  <p className='user-name mb-0'>
                    {userData?.first_name}
                    {'   '}
                    {userData?.last_name}
                  </p>
                  <p className='designattion mb-0'>Web Designer</p>
                </div>
              </a>
              <ul className='dropdown-menu dropdown-menu-end'>
                <li>
                  <Link
                    className='dropdown-item'
                    onClick={logout}
                    // href="authentication-signin.html"
                  >
                    <i className='bx bx-log-out-circle pe-2'></i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
