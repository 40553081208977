import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

const MySubscr = (props) => {
  const [data, setData] = useState({});
  const [availablePlans, setAvailablePlans] = useState([]);
  const { addToast } = useToasts();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('access_token');
  //   const { data, availablePlans } = props;
  //   console.log(props, 'data');
  //   const [data, setData] = useState([]);

  //   useEffect(() => {
  //     fetch('https://61cc1144198df60017aebe5f.mockapi.io/subscription').then(
  //       (result) => {
  //         result.json().then((resp) => {
  //           setData(resp);
  //         });
  //       }
  //     );
  //   }, []);
  //   console.log('data', data);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/subscriptions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setData(response?.data?.data?.currentSubscription);
        setAvailablePlans(response?.data?.data?.availablePlans);
      })
      .catch((error) => {
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className='page-wrapper'>
        <div div className='breadcrunbs'>
          <h6 className='blue-text semibold-text mb-0'>My Subscription</h6>
        </div>

        <div className='page-content'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card p-3'>
                <p className='mb-3 fw-bold'>Current Subscription</p>
                <div div className='table-responsive'>
                  <table
                    id='example'
                    className='table'
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Subscription</th>
                        <th>Status</th>
                        <th>Renewal Date</th>
                        <th>Initial Amount</th>
                        <th>Times Billed</th>
                        <th className='text-right pr-5'>
                          <span className='mr-4'>Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* {data.map((item, ind) => {
                          return ( */}
                        <>
                          <td> {data?.subcription}</td>
                          <td>
                            <span className='text-success'>{data?.status}</span>
                          </td>
                          <td>{data?.renewalDate}</td>
                          <td>{data?.initialAmount}</td>
                          <td>{data?.timesBilled}</td>
                          <td>
                            <Link to='#' className='text-danger float-end'>
                              {data?.actions}
                            </Link>
                          </td>
                        </>
                        {/* );
                        })} */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card p-3 m-0'>
                  <p className='mb-3 fw-bold'>Available Plans</p>
                  <div className='row'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='row'>
                          {availablePlans.map((item, idx) => {
                            return (
                              <>
                                <div
                                  key={idx}
                                  className='col-lg-4 col-md-6 col-12'
                                >
                                  <div className='card basic'>
                                    {/* {plan.type === 'Standard' ? (
                                      <div
                                        className='card-header text-center text-white'
                                        style={{
                                          backgroundColor: '#26B764',
                                        }}
                                      >
                                        <p className='mb-0 text_first'>
                                          {plan.type}
                                        </p>
                                        <p className='mb-0 text_middle'>
                                          {plan.amount}
                                        </p>
                                        <p className='mb-0 text_last'>
                                          per month
                                        </p>
                                      </div>
                                    ) : ( */}
                                    <div className='card-header text-center '>
                                      <p className='mb-0 text_first'>
                                        {item?.package_title}
                                      </p>
                                      <p className='mb-0 text_middle'>
                                        ${item?.fee}
                                      </p>
                                      <p className='mb-0 text_last'>
                                        per {item?.duration_type}
                                      </p>
                                    </div>
                                    {/* )} */}
                                    <div className='card-body'>
                                      <ul className='list-unstyled mb-0 mt-2'>
                                        <li>
                                          <span className='text-success'>
                                            <i className='fas fa-check'></i>
                                          </span>
                                          {item?.user_count}
                                        </li>
                                        <li>
                                          {item?.email_support === 0 ? (
                                            <span className='text-danger'>
                                              <i className='fas fa-times'></i>
                                            </span>
                                          ) : (
                                            <span className='text-success'>
                                              <i className='fas fa-check'></i>
                                            </span>
                                          )}
                                          Email support
                                        </li>
                                        <li>
                                          {item?.unlimited_statistic === 0 ? (
                                            <span className='text-danger'>
                                              <i className='fas fa-times'></i>
                                            </span>
                                          ) : (
                                            <span className='text-success'>
                                              <i className='fas fa-check'></i>
                                            </span>
                                          )}
                                          Unlimited statistic
                                        </li>
                                        <li>
                                          {item?.export_project === 0 ? (
                                            <span className='text-danger'>
                                              <i className='fas fa-times'></i>
                                            </span>
                                          ) : (
                                            <span className='text-success'>
                                              <i className='fas fa-check'></i>
                                            </span>
                                          )}
                                          Export projects
                                        </li>
                                      </ul>
                                    </div>
                                    <div className='card-footer border-0 text-center mb-4'>
                                      <Link to='#' className='btn btn-primary'>
                                        Subscribe
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* <div className='row'>
                      {availablePlans.map((item, idx) => {
                        return (
                          <div className='col-12'>
                            <div className='row'>
                              {item.availablePlans.map((plan, idx) => {
                                return (
                                  <>
                                    <div
                                      key={idx}
                                      className='col-lg-4 col-md-6 col-12'
                                    >
                                      <div className='card basic'>
                                        {plan.type === 'Standard' ? (
                                          <div
                                            className='card-header text-center text-white'
                                            style={{
                                              backgroundColor: '#26B764',
                                            }}
                                          >
                                            <p className='mb-0 text_first'>
                                              {plan.type}
                                            </p>
                                            <p className='mb-0 text_middle'>
                                              {plan.amount}
                                            </p>
                                            <p className='mb-0 text_last'>
                                              per month
                                            </p>
                                          </div>
                                        ) : (
                                          <div className='card-header text-center '>
                                            <p className='mb-0 text_first'>
                                              {plan.type}
                                            </p>
                                            <p className='mb-0 text_middle'>
                                              {plan.amount}
                                            </p>
                                            <p className='mb-0 text_last'>
                                              per month
                                            </p>
                                          </div>
                                        )}
                                        <div className='card-body'>
                                          <ul className='list-unstyled mb-0 mt-2'>
                                            <li>
                                              <span className='text-success'>
                                                <i className='fas fa-check'></i>
                                              </span>
                                              {plan.user}
                                            </li>
                                            <li>
                                              <span className='text-success'>
                                                <i className='fas fa-check'></i>
                                              </span>
                                              {plan.support}
                                            </li>
                                            <li>
                                              {plan.type === 'Standard' ||
                                              plan.type === 'Premium' ? (
                                                <span className='text-success'>
                                                  <i className='fas fa-check'></i>
                                                </span>
                                              ) : (
                                                <span className='text-danger'>
                                                  <i className='fas fa-times'></i>
                                                </span>
                                              )}

                                              {plan.statistic}
                                            </li>
                                            <li>
                                              {plan.type === 'Premium' ? (
                                                <span className='text-success'>
                                                  <i className='fas fa-check'></i>
                                                </span>
                                              ) : (
                                                <span className='text-danger'>
                                                  <i className='fas fa-times'></i>
                                                </span>
                                              )}
                                              {plan.projects}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className='card-footer border-0 text-center mb-4'>
                                          <Link
                                            to='#'
                                            className='btn btn-primary'
                                          >
                                            Subscribe
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySubscr;
