import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const MainFooter = (showBelow) => {
    const [show, setShow] = useState(showBelow ? false : true)
    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const scrollToTop = () => {
        window['scroll']({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        console.log('effect')
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }

    }, []);

    let currentDate = new Date();
    let year = currentDate.getFullYear();

    return (
        <>
            <div className="overlay toggle-icon"></div>
            {/* <Link to="#" className="back-to-top"> */}
            <Link to="#" className='back-to-top'>
                {/* <button onClick={scrollToTop}>up</button> */}
                <i onClick={scrollToTop} className="bx bxs-up-arrow-alt"></i>
            </Link>

            <footer className="page-footer">


                <p className="mb-0">Copyright © {year}. All right reserved.</p>
            </footer>
        </>
    )
}

export default MainFooter


