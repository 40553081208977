import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthHeader from '../component/MainHeader/components dashboard/AuthHeader';
import AuthLogo from '../component/MainHeader/components dashboard/AuthLogo';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
const ResetPassword = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [confirmpassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const [inputValue, setInputValue] = useState([]);
  const [loginError, setLoginError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  //   let { token } = useParams();
  //   console.log(token, 'token');
  const currentUrl = window.location.href;

  // Parse the URL to extract query parameters
  const urlParams = new URLSearchParams(currentUrl.split('?')[1]);

  // Get the value of the "id" query parameter
  const token = urlParams.get('token');
  console.log(token, 'token');
  const validate = (values) => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.password) {
      errors.password = 'Password is Required !';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is Required!';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match!';
    }

    return errors;
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const login = () => {
    setFormErrors(validate(inputValue));

    if (
      inputValue.password &&
      inputValue.confirmPassword &&
      inputValue.password === inputValue.confirmPassword
    ) {
      let data = JSON.stringify({
        password: password,

        token: token,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/reset-password`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
          });

          localStorage.removeItem('access_token');
          localStorage.removeItem('user');

          history.push('/authentication-signin.html');
        })
        .catch((error) => {
          addToast(error?.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log(error);
        });
    } else {
      setLoginError('Please enter valid email and password');
    }
  };

  return (
    <>
      {/* <!--wrapper--> */}
      <section className='main'>
        <AuthHeader />
        <div className='right-side text-center'>
          <AuthLogo />
          <form>
            <div className='mb-3'>
              <input
                type='password'
                name='password'
                placeholder='Password'
                className='form-control'
                autoComplete='Off'
                value={inputValue.password}
                onChange={inputHandler}
              />
              {!inputValue.password && (
                <p style={{ color: 'red', textAlign: 'left' }}>
                  {formErrors.password}
                </p>
              )}
            </div>
            <div className='mb-3'>
              <input
                type='text'
                name='confirmPassword'
                placeholder='Confirm Password'
                className='form-control'
                value={inputValue.confirmPassword}
                onChange={inputHandler}
              />
              {(!inputValue.confirmPassword ||
                inputValue.password !== inputValue.confirmPassword) && (
                <p style={{ color: 'red', textAlign: 'left' }}>
                  {formErrors.confirmPassword}
                </p>
              )}
            </div>
            <Link to='#' className='btn btn-primary' onClick={login}>
              SIGN IN
            </Link>
          </form>
          <p className='mt-4'>
            Don't have an account{' '}
            <Link to='/authentication-signup.html'>Sign Up</Link>
          </p>
          <ul className='list-inline list-unstyled social-media-list'>
            <li className='list-inline-item'>
              <Link to='#'>
                <img
                  src='assets/images/facebook.png'
                  width='35'
                  height='35'
                  className='img-fluid rounded-circle'
                  alt='facebook'
                />
              </Link>
            </li>
            <li className='list-inline-item'>
              <Link to='#'>
                <img
                  src='assets/images/google-plus.png'
                  width='35'
                  height='35'
                  className='img-fluid rounded-circle'
                  alt='google+'
                />
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
