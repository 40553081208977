import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthHeader from '../component/MainHeader/components dashboard/AuthHeader';
import AuthLogo from '../component/MainHeader/components dashboard/AuthLogo';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
const ForgotPassword = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inputValue, setInputValue] = useState([]);
  const [loginError, setLoginError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const login = () => {
    // inputValue.map((user) => {
    if (email) {
      let data = JSON.stringify({
        email: email,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/forgot-password`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
          });
          //   history.push('/reset-password.html');
        })
        .catch((error) => {
          addToast(error?.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log(error);
        });
    } else {
      setLoginError('Please enter valid email and password');
    }
    // })
  };

  return (
    <>
      {/* <!--wrapper--> */}
      <section className='main'>
        <AuthHeader />
        <div className='right-side text-center'>
          <AuthLogo />
          <form>
            <div className='mb-3'>
              <input
                type='text'
                name='email'
                placeholder='Email'
                className='form-control'
                onChange={(e) => setEmail(e.target.value)}
              />
              <p style={{ color: 'red', textAlign: 'left' }}>{loginError}</p>
            </div>

            <Link to='#' className='btn btn-primary' onClick={login}>
              Send
            </Link>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
