import React from 'react'

const Dashbo = () => {
    return (
        <div className="page-wrapper">
            <div className="breadcrunbs">
                <h6 className="blue-text semibold-text mb-0">Dashboard</h6>
            </div>
            <div className="page-content">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-12">
                    <div className="col mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Recent Statistic</p>
                                    </div>
                                </div>
                                <img src="assets/images/chart1.png" className="img-fluid" alt="chart" />
                            </div>
                        </div>
                    </div>
                    <div className="col mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Recent Statistic</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/pie-chart.png"
                                    className="img-fluid"
                                    alt="pie-chart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-xl-2 mb-3">
                    <div className="col mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Recent Statistic</p>
                                    </div>
                                </div>
                                <img src="assets/images/chart1.png" className="img-fluid" alt="chart" />
                            </div>
                        </div>
                    </div>
                    <div className="col mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Recent Statistic</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/pie-chart.png"
                                    className="img-fluid"
                                    alt="pie-chart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashbo
