import React from 'react'
import Footer from '../component/subscription/Footer'
import Header from '../component/subscription/Header'
import { Link } from "react-router-dom";

const Payment = () => {
    return (
        <>
            <Header />
            {/* <!-- Page wrapper end --> */}

            {/* <!--wrapper--> */}
            <section className="custom-margin-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="payment-card">
                                <div className="card-left">
                                    <Link to="subscription.html" className="btn blue-btn mb-5"><i className="fas fa-arrow-left"></i>Back</Link>
                                    <h3 className="white-text mb-4 pt-3 text-center">Choose a payment option</h3>
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                            <img src="assets/images/credit-card.png" className="img-fluid me-3" width="20" alt='creditCard' />Credit card <span className="float-end" ><i className="fas fa-chevron-right"></i></span></button>
                                        <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                            <img src="assets/images/paypal-logo.png" className="img-fluid me-3" width="20" alt='paypal' />Paypal <span className="float-end"><i className="fas fa-chevron-right"></i></span></button>
                                        <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                            <img src="assets/images/cards.png" className="img-fluid me-3" width="25" alt='cards' />Stripe <span className="float-end"><i className="fas fa-chevron-right"></i></span></button>
                                    </div>
                                </div>
                                <div className="card-right">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h3><strong>Total Amount :</strong></h3>
                                                <h3><strong>$ 399</strong></h3>
                                            </div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="mb-2">Cardholder Name</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6  mt-3">
                                                        <label className="mb-2">Card Number</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">CVV</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">Expiry Date</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Save my payment details for future purchase.</label>
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <Link to="payment-successful.html" className="btn btn-primary">Subscribe</Link>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h3><strong>Total Amount :</strong></h3>
                                                <h3><strong>$ 399</strong></h3>
                                            </div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="mb-2">Cardholder Name</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6  mt-3">
                                                        <label className="mb-2">Card Number</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">CVV</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">Expiry Date</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Save my payment details for future purchase.</label>
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <Link to="payment-successful.html" className="btn btn-primary">Subscribe</Link>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h3><strong>Total Amount :</strong></h3>
                                                <h3><strong>$ 399</strong></h3>
                                            </div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="mb-2">Cardholder Name</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6  mt-3">
                                                        <label className="mb-2">Card Number</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">CVV</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3  mt-3">
                                                        <label className="mb-2">Expiry Date</label>
                                                        <input type="text" name="name" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Save my payment details for future purchase.</label>
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <Link to="payment-successful.html" className="btn btn-primary">Subscribe</Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--end wrapper--> */}
            <Footer />

        </>
    )
}

export default Payment
