import React from 'react'

const UsageDatee = () => {

    return (
        <div className="page-wrapper">
            <div className="breadcrunbs">
                <h6 className="blue-text semibold-text mb-0">Usage Date</h6>
            </div>
            <div className="page-content">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-12">
                    <div className="col-lg-8 mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Active Users</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/active-users-chart.png"
                                    className="img-fluid"
                                    alt="userchart"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Activity</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/activity-pie-chart.png"
                                    className="img-fluid d-block mx-auto"
                                    width="220"
                                    alt="activitychart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--end row--> */}
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-12">
                    <div className="col-lg-8 mb-4 mb-lg-0">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Activity</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/activity-barchart.png"
                                    className="img-fluid"
                                    alt="barchart"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="card radius-10 h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-3 fw-bold">Activity</p>
                                    </div>
                                </div>
                                <img
                                    src="assets/images/activity-line-chart.png"
                                    className="img-fluid d-block mx-auto mt-0 mt-md-4"
                                    alt="linechart"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsageDatee
