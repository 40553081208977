import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { Buffer } from 'buffer';
const Profi = ({
  setUserData = () => {},
  proFilePic = null,
  setProFilePic = () => {},
}) => {
  const [state, setState] = useState('');
  const { addToast } = useToasts();

  let userData = {};
  userData = JSON.parse(localStorage.getItem('user'));
  const [countries, setCountries] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(userData, 'getData');
  const token = localStorage.getItem('access_token');
  useEffect(() => {
    setData(userData);
  }, []);

  const [password, setPassword] = useState({
    // current_password: '',
    // new_password: '',
    // confirm_password: '',
  });
  const image = localStorage.getItem('profileimg');

  const [picture, setPicture] = useState(null);
  const [profileImg, serProfileImg] = useState(image);

  const [data, setData] = useState({
    id: userData.id,
    firstName: userData.first_name,
    lastname: userData.last_name,
    email: userData.email,
    phoneNumber: userData.phone_number,
    country: userData.country,
    state: userData.state,
    city: userData.city,
    address: userData.address,
    zcode: userData.zip,
  });
  console.log(data, '========DATA===');
  const updatedData = {
    //  id: data.id,
    first_name: data.firstName,
    last_name: data.lastname,
    email: data.email,
    phone_number: data.phoneNumber,
    country: data.country,
    state: data.state,
    city: data.city,
    address: data.address,
    zip: data.zcode,
  };
  console.log(updatedData, 'updatedData');
  //password
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPassword({ ...password, [name]: value });
  };

  const submitPassword = (e) => {
    e.preventDefault();
    const newRecord = { ...password };

    let data = JSON.stringify(newRecord);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/change-password`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

        setPassword({
          current_password: '',
          new_password: '',
          confirm_password: '',
        });
      })
      .catch((error) => {
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log(error);
      });
  };

  // updating profile data
  const onChange = (e) => {
    const updatedName = e.target.name;
    const updatedValue = e.target.value;
    setData({ ...data, [updatedName]: updatedValue });
  };
  // update api
  const submitUserData = (e) => {
    e.preventDefault();

    let data = JSON.stringify(updatedData);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/update-profile`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setUserData(response?.data?.data);
        localStorage.setItem('user', JSON.stringify(response?.data?.data));
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const fileUploadHandler = (e) => {
    e.preventDefault();

    console.log(e, 'e');
    const fileInput = e?.target?.files[0]; // Get the uploaded file
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        const profileImgData = reader.result; // Get the base64 encoded image data
        serProfileImg(profileImgData); // Update the profile image in state
        // uploadProfilePhoto(profileImgData); // Call the function to upload the profile photo
      }
    };

    reader.readAsDataURL(fileInput); // Read the file as data URL
  };

  const uploadProfilePhoto = () => {
    const data = new FormData();
    // const file = Buffer.from(profileImg.split(',')[1], 'base64'); // Convert base64 string to buffer
    //console.log(file, 'file-----');
    // Append the file to FormData
    // data.append('photo', profileImg, 'profile-photo.jpg');
    const byteCharacters = atob(profileImg.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Adjust the type as per your image format

    // Append the Blob to FormData
    data.append('photo', blob, 'profile-photo.jpg');

    const config = {
      method: 'post',
      url: 'https://trustwrx-admin.theclosedoor.com/api/v1/profile/photo',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`, // Replace with your access token
        //  ...data.getHeaders(),
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        localStorage.setItem(
          'profileimg',
          response?.data?.user?.profile_pic_image?.url
        );
        setProFilePic(response?.data?.user?.profile_pic_image?.url);
        // Handle success if needed
      })
      .catch((error) => {
        console.log(error);
        // Handle error if needed
      });
  };

  useEffect(() => {
    setPicture(localStorage.getItem('profileimg'));
  }, []);

  const fetchData = async () => {
    const countryData = await fetch(`${apiUrl}/api/countries`);
    const countryJson = await countryData.json();
    console.log(countryJson, 'countryData');

    setCountries(countryJson?.countries);
  };
  useEffect(() => {
    fetchData();
  }, []);

  //state data

  const stateFetchData = async () => {
    const stateData = await fetch(`${apiUrl}/api/states`);
    const stateJson = await stateData.json();
    setState(stateJson?.states);
  };
  state &&
    state.map((statess) => {
      console.log('state', statess.state);
    });

  useEffect(() => {
    stateFetchData();
  }, []);

  return (
    <div className='page-wrapper'>
      <div className='breadcrunbs'>
        <h6 className='blue-text semibold-text mb-0'>Profile</h6>
      </div>
      <div className='page-content profile-tab'>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 col-md-4'>
            <div className='card p-3 h-100'>
              <div className='d-flex align-items-center mb-3'>
                <div className='flex-shrink-0'>
                  <img
                    src={proFilePic}
                    alt='user'
                    className='img-fluid'
                    width='50'
                  />
                </div>
                <div className='flex-grow-1 ms-3'>
                  <p className='mb-0'>
                    <strong>
                      {data.first_name} {data.last_name}
                    </strong>
                  </p>
                </div>
              </div>
              <div className='d-flex mb-2'>
                <label className='me-3 d-block'>Email:</label>
                <p className='mb-0 lightgray-text'>{data.email}</p>
              </div>
              <div className='d-flex'>
                <label className='me-2 d-block'>Phone:</label>
                <p className='mb-0 lightgray-text'>{data.phone_number}</p>
              </div>

              <div className='d-flex align-items-start mt-4'>
                <div
                  className='nav flex-column nav-pills me-3 w-100 profile-nav'
                  id='v-pills-tab'
                  role='tablist'
                  aria-orientation='vertical'
                >
                  <button
                    className='nav-link active'
                    id='v-pills-home-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-home'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-home'
                    aria-selected='true'
                  >
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-user me-3'></i>
                      Personal <br />
                      Information
                    </div>
                  </button>
                  <button
                    className='nav-link'
                    id='v-pills-profile-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-profile'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-profile'
                    aria-selected='false'
                  >
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-lock me-3'></i>
                      Change <br />
                      Password
                    </div>
                  </button>
                  <button
                    className='nav-link'
                    id='v-pills-messages-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-messages'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-messages'
                    aria-selected='false'
                  >
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-upload me-3'></i>
                      Upload Profile <br />
                      Photo
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-9 col-lg-8 col-md-8'>
            <div className='card h-100 mt-3 mt-md-0'>
              <div className='tab-content' id='v-pills-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='v-pills-home'
                  role='tabpanel'
                  aria-labelledby='v-pills-home-tab'
                >
                  <div className='card-header bg-white'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <div>
                        <p className='lead mb-1 semibold-text'>
                          Personal Information
                        </p>
                        <p className='lightgray-text mb-0 small'>
                          Update your personal information
                        </p>
                      </div>
                      <Link
                        to='#'
                        className='btn btn-primary btn-md mt-2 mt-md-0'
                        onClick={submitUserData}
                      >
                        Save Changes
                      </Link>
                    </div>
                  </div>
                  <div className='card-body mt-3'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='firstName'
                          placeholder='First Name'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.firstName}
                          onChange={onChange}
                        />
                      </div>

                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='lastname'
                          placeholder='Last Name '
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.lastname}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg-12'>
                        <input
                          type='text'
                          name='email'
                          placeholder='Email'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.email}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg-12'>
                        <input
                          type='text'
                          name='phoneNumber'
                          placeholder='Phone Number'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.phoneNumber}
                          onChange={onChange}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg-12'>
                        <input
                          type='text'
                          name='address'
                          placeholder='Address'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.address}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='city'
                          placeholder='City'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.city}
                          onChange={onChange}
                        />
                        {/* <select
                          className='form-control me-0 mb-2 me-md-2 mb-md-0'
                          id='state'
                          name='city'
                          onChange={onChange}
                          value={data.state}
                        >
                          {state &&
                            state.map((items, key) => {
                              return (
                                <>
                                  <option key={key} value={items.city}>
                                    {items.city}
                                  </option>
                                </>
                              );
                            })}
                        </select> */}
                      </div>
                      <div className='col-lg-6'>
                        <select
                          className='form-control me-0 mb-2 me-md-2 mb-md-0'
                          id='state'
                          name='state'
                          onChange={onChange}
                          value={data.state}
                        >
                          {state &&
                            state.map((items, key) => {
                              return (
                                <>
                                  <option
                                    key={key}
                                    value={items?.vlongname_state}
                                  >
                                    {items?.vlongname_state}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <select
                          type='text'
                          name='country'
                          placeholder='Country'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.country}
                          onChange={onChange}
                        >
                          {/* <option>USA</option> */}
                          {countries &&
                            countries.map((item, key) => {
                              return (
                                <>
                                  <option key={key} value={item?.vlongname}>
                                    {item?.vlongname}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='zcode'
                          placeholder='Zip Code'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          value={data.zcode}
                          onChange={onChange}
                        />
                        {/* <select
                          className='form-control me-0 mb-2 me-md-2 mb-md-0'
                          id='state'
                          name='zcode'
                          onChange={onChange}
                        >
                          {state &&
                            state.map((items, key) => {
                              return (
                                <>
                                  <option key={key} value={items.pincode}>
                                    {items.pincode}
                                  </option>
                                </>
                              );
                            })}
                        </select> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='v-pills-profile'
                  role='tabpanel'
                  aria-labelledby='v-pills-profile-tab'
                >
                  <div className='card-header bg-white'>
                    <div
                      className='
                            d-flex
                            align-items-center
                            justify-content-between
                            flex-wrap
                          '
                    >
                      <div>
                        <p className='lead mb-1 semibold-text'>
                          Change password
                        </p>
                        <p className='lightgray-text mb-0 small'>
                          Update your account password
                        </p>
                      </div>
                      <button
                        to='#'
                        className='btn btn-primary btn-md mt-2 mt-md-0'
                        onClick={submitPassword}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>

                  <div className='card-body mt-3'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='current_password'
                          placeholder='Current Password'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          onChange={inputHandler}
                        />
                      </div>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='new_password'
                          placeholder='New Password'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          onChange={inputHandler}
                        />
                      </div>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          name='confirm_password'
                          placeholder='Confirm Password'
                          className='form-control me-0 mb-2 me-md-2 mb-md-3'
                          onChange={inputHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* upload image  */}
                <div
                  className='tab-pane fade'
                  id='v-pills-messages'
                  role='tabpanel'
                  aria-labelledby='v-pills-messages-tab'
                >
                  <div className='card-header bg-white'>
                    <div className=' d-flex align-items-center justify-content-between flex-wrap'>
                      <div>
                        <p className='lead mb-1 semibold-text'>
                          Upload Profile Picture
                        </p>
                        <p className='lightgray-text mb-0 small'>
                          Update your picture
                        </p>
                      </div>
                      <Link
                        to='#'
                        className='btn btn-primary btn-md mt-2 mt-md-0'
                        onClick={uploadProfilePhoto}
                      >
                        Save Changes
                      </Link>
                    </div>
                  </div>
                  <div className='card-body mt-3'>
                    <div className='row'>
                      <div className='col-lg-9'>
                        <div className='row align-items-center'>
                          <div className='col-lg-3'>
                            <label> Profile Picture </label>
                          </div>
                          <div className='col-lg-9'>
                            <div className='avatar-upload'>
                              {/* file input  */}
                              <div className='avatar-edit'>
                                <input
                                  type='file'
                                  id='imageUpload'
                                  accept='.png, .jpg, .jpeg'
                                  onChange={fileUploadHandler}
                                />
                                <label htmlFor='imageUpload'></label>
                              </div>
                              <div className='avatar-preview'>
                                <div id='imagePreview'>
                                  <img src={profileImg} alt='' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profi;
