import React, { useEffect, useState } from 'react';
import Footer from '../component/subscription/Footer';
import Header from '../component/subscription/Header';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

const Subscription = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const token = localStorage.getItem('access_token');
  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/subscription-packages`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setData(response?.data?.data);
        console.log(JSON.stringify(response?.data));
      })
      .catch((error) => {
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log(error);
      });
  }, []);
  const handleClick = (id) => {
    let data = JSON.stringify({
      stripe_package_id: id,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/subscriptions`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        history.push('payment-successful.html');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Header />
      {/* <!--wrapper--> */}
      <section className='custom-margin-top pricebox'>
        <div className='container'>
          <div className='row'>
            <h3 className='mb-4 mt-3 fw-bold text-center'>
              Choose your subscription plan
            </h3>
            {data.length > 0 &&
              data?.map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 col-12'>
                  <div className='card basic'>
                    <div className='card-header text-center'>
                      <p className=' mb-0 white-text text_first'>
                        {item?.package_title}
                      </p>
                      <p className=' mb-0 white-text text_middle'>
                        ${item?.fee}
                      </p>
                      <p className=' mb-0 white-text text_last'>
                        per {item?.duration_type}
                      </p>
                    </div>
                    <div className='card-body'>
                      <ul className='list-unstyled mb-0 mt-2'>
                        <li>
                          <span className='text-success'>
                            <i className='fas fa-check'></i>
                          </span>
                          {item?.user_count}
                        </li>
                        <li>
                          {item?.email_support === 0 ? (
                            <span className='text-danger'>
                              <i className='fas fa-times'></i>
                            </span>
                          ) : (
                            <span className='text-success'>
                              <i className='fas fa-check'></i>
                            </span>
                          )}
                          Email support
                        </li>
                        <li>
                          {item?.unlimited_statistic === 0 ? (
                            <span className='text-danger'>
                              <i className='fas fa-times'></i>
                            </span>
                          ) : (
                            <span className='text-success'>
                              <i className='fas fa-check'></i>
                            </span>
                          )}
                          Unlimited statistic
                        </li>
                        <li>
                          {item?.export_project === 0 ? (
                            <span className='text-danger'>
                              <i className='fas fa-times'></i>
                            </span>
                          ) : (
                            <span className='text-success'>
                              <i className='fas fa-check'></i>
                            </span>
                          )}
                          Export projects
                        </li>
                      </ul>
                    </div>
                    <div className='card-footer border-0 text-center mb-4'>
                      {/* <Link to='payment.html' className='btn btn-primary'>
                    Subscribe
                  </Link> */}
                      <Link
                        // to={`payment-successful.html?id=${item?.id}`}
                        className='btn btn-primary'
                        onClick={() => handleClick(item?.id)}
                      >
                        Subscribe
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div className='col-lg-4 col-md-6 col-12'>
              <div className='card standard'>
                <div className='card-header text-center'>
                  <p className='white-text mb-0 text_first'>Satandard</p>
                  <p className='white-text mb-0 text_middle'>$299</p>
                  <p className='white-text mb-0 text_last'>per month</p>
                </div>
                <div className='card-body'>
                  <ul className='list-unstyled mb-0 mt-2'>
                    <li>
                      <span className='text-success'>
                        <i className='fas fa-check'></i>
                      </span>
                      Up to 2 users
                    </li>
                    <li>
                      <span className='text-success'>
                        <i className='fas fa-check'></i>
                      </span>
                      Email support
                    </li>
                    <li>
                      <span className='text-success'>
                        <i className='fas fa-check'></i>
                      </span>
                      Unlimited statistic
                    </li>
                    <li>
                      <span className='text-danger'>
                        <i className='fas fa-times'></i>
                      </span>
                      Export projects
                    </li>
                  </ul>
                </div>
                <div className='card-footer border-0 text-center mb-4'>
                  <Link
                    to='payment-successful.html'
                    className='btn btn-primary'
                  >
                    Subscribe
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <!--end wrapper--> */}
      <Footer />
    </>
  );
};

export default Subscription;
