import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Payment from './routes/Payment';
import PaymentSuccessful from './routes/PaymentSuccessful';
import SignUp from './routes/SignUp';
import Subscription from './routes/Subscription';
import Profile from './routes/Profile';
import TrustUser from './component/trustuser/TrustUser';
import AuthSignIn from './routes/AuthSignIn';
import Dashboard from './routes/Dashboard';
import MySubscription from './routes/MySubscription';
import UsageDate from './routes/UsageDate';
import Devices from './routes/Devices';
import Error from './component/error/Error';
import ThreatMonitor from './routes/ThreadMonitor';
import PublicRoute from './PublicRoutes';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './routes/ForgotPassword';
import ResetPassword from './routes/ResetPassword';
// import { createBrowserHistory } from "history";

function App() {
  // const history = createBrowserHistory();

  return (
    <BrowserRouter basename='/'>
      <div className='App'>
        <Switch>
          <PublicRoute exact path='/index.html' component={TrustUser} />
          <PublicRoute exact path='/' component={AuthSignIn} />
          <PublicRoute
            exact
            path='/authentication-signin.html'
            component={AuthSignIn}
          />
          <PrivateRoute
            exact
            path='/subscription.html'
            component={Subscription}
          />
          <PublicRoute
            exact
            path='/authentication-signup.html'
            component={SignUp}
          />
          <PrivateRoute exact path='/payment.html' component={Payment} />
          <PublicRoute
            exact
            path='/forgotpassword.html'
            component={ForgotPassword}
          />

          <PrivateRoute
            exact
            path='/payment-successful.html'
            component={PaymentSuccessful}
          />

          <PublicRoute
            exact
            path='/reset-password.html'
            component={ResetPassword}
          />
          <PrivateRoute exact path='/dashboard.html' component={Dashboard} />
          <PrivateRoute exact path='/profile.html' component={Profile} />
          <PrivateRoute
            exact
            path='/my-subscription.html'
            component={MySubscription}
          />
          <PrivateRoute exact path='/usage-date.html' component={UsageDate} />
          <PrivateRoute exact path='/devices.html' component={Devices} />
          <PrivateRoute
            exact
            path='/threat-monitor.html'
            component={ThreatMonitor}
          />
          <PrivateRoute component={Error} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
