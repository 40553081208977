import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'


const MainNav = ({ handleToggle }) => {



    return (
        <div className="sidebar-wrapper" data-simplebar="true">
            {/* <div className="sidebar-header" > */}
            <div className={`sidebar-header`}
            >
                <div onClick={handleToggle}>
                    <img
                        src="assets/images/hamburger-menu.png"
                        className="img-fluid menubar-icon"
                        width="20"
                        alt="logo icon"

                    />

                </div>
                <div >
                    <h4 className="logo-text ms-0">
                        <img
                            src="assets/images/small-logo.png"
                            className="logo-icon"
                            alt="logo icon"
                        />
                    </h4>
                </div>
                <div className="toggle-icon ms-auto "
                    onClick={handleToggle}
                >
                    <i className="bx bx-arrow-to-left"  ></i>
                </div>
            </div>
            {/* <!--navigation--> */}
            <ul className="metismenu p-0 pt-4" id="menu">
                <li>
                    <Link to="/dashboard.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/home.png"
                                width="22"
                                className="img-fluid"
                                alt="home"
                            />
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </Link>
                </li>
                <li>
                    <Link to="/profile.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/user.png"
                                width="24"
                                className="img-fluid"
                                alt="user"
                            />
                        </div>
                        <div className="menu-title">Profile</div>
                    </Link>
                </li>
                <li>
                    <Link to="/my-subscription.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/renew.png"
                                width="24"
                                className="img-fluid"
                                alt="renew"
                            />
                        </div>
                        <div className="menu-title">My Subscription</div>
                    </Link>
                </li>
                <li>
                    <Link to="/usage-date.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/data.png"
                                width="22"
                                className="img-fluid"
                                alt="data"
                            />
                        </div>
                        <div className="menu-title">Usage Date</div>
                    </Link>
                </li>
                <li>
                    <Link to="/devices.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/share.png"
                                width="24"
                                className="img-fluid"
                                alt="share"
                            />
                        </div>
                        <div className="menu-title">Devices</div>
                    </Link>
                </li>
                <li>
                    <Link to="/threat-monitor.html">
                        <div className="parent-icon">
                            <img
                                src="assets/images/research.png"
                                width="24"
                                className="img-fluid"
                                alt="research"
                            />
                        </div>
                        <div className="menu-title">Threat Monitor</div>
                    </Link>
                </li>
            </ul>
            {/* <!--end navigation--> */}
        </div>
    )
}

export default MainNav




