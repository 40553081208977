import React from 'react'

const AuthHeader = () => {
    return (
        <div className="left-side text-center">
            <h1 className="white-text">
                Welcome to <br /> <strong>TrustWrx</strong>
            </h1>
            <p className="white-text lead mt-3">
                <i>
                    security matters.. <br /> Now more than ever
                </i>
            </p>
        </div>
    )
}

export default AuthHeader
