import React from "react";

const Footer = () => {

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="mb-0 text-center">
                                copyright © {year} all rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
