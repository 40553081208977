import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = () => {
    // Check if user is authenticated (e.g., check for access token)
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken; // Returns true if access token exists, otherwise false
  };
  const isUserData = () => {
    // Check if user data exists in localStorage
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    console.log(userData, 'asdsad');
    return userData?.subscriptions?.length; // Returns true if user data exists, otherwise false
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          isUserData() ? (
            <Redirect
              to={{
                pathname: '/dashboard.html',
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/subscription.html',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
