import React, { useEffect, useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Profi from '../component/MainHeader/components dashboard/Profi';
import MainFooter from '../component/MainHeader/MainFooter';
import MainHeader from '../component/MainHeader/MainHeader';
import MainNav from '../component/MainHeader/MainNav';

const Profile = () => {
  const [toggled, setToggled] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const [proFilePic, setProFilePic] = useState(
    localStorage.getItem('profileimg')
  );

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('user') || '{}'));
  }, [localStorage.getItem('user')]);

  useEffect(() => {
    setProFilePic(localStorage.getItem('profileimg'));
  }, [localStorage.getItem('profileimg')]);

  return (
    <Fragment>
      <div className={`wrapper ${toggled ? 'toggled' : ''}`}>
        <MainHeader
          handleToggle={() => {
            setToggled(!toggled);
          }}
          user_Data={userData}
          proFilePic={proFilePic}
        />
        <MainNav
          handleToggle={() => {
            setToggled(!toggled);
          }}
        />
        {/* <!--end navigation--> */}
        {/* <!--start page wrapper --> */}
        <Profi
          setUserData={setUserData}
          proFilePic={proFilePic}
          setProFilePic={setProFilePic}
        />
        {/* <!--start overlay--> */}
        <MainFooter />
      </div>
    </Fragment>
  );
};

export default Profile;
