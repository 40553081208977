import React, { useEffect, useState } from 'react';
import MySubscr from '../component/MainHeader/components dashboard/MySubscr';
import MainFooter from '../component/MainHeader/MainFooter';
import MainHeader from '../component/MainHeader/MainHeader';
import MainNav from '../component/MainHeader/MainNav';
import axios from 'axios';
const MySubscription = () => {
  const [toggled, setToggled] = useState(false);
  //   const id = localStorage.getItem('subscription_id');
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('access_token');
  const [data, setData] = useState({});
  const [availablePlans, setAvailablePlans] = useState([]);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [proFilePic, setProFilePic] = useState(
    localStorage.getItem('profileimg')
  );
  useEffect(() => {
    setProFilePic(localStorage.getItem('profileimg'));
  }, [localStorage.getItem('profileimg')]);
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('user') || '{}'));
  }, [localStorage.getItem('user')]);

  return (
    <div className={`wrapper ${toggled ? 'toggled' : ''}`}>
      <MainHeader
        handleToggle={() => {
          setToggled(!toggled);
        }}
        user_Data={userData}
        proFilePic={proFilePic}
      />
      {/* <!--end navigation--> */}
      <MainNav
        handleToggle={() => {
          setToggled(!toggled);
        }}
      />
      {/* <!--start page wrapper --> */}
      <MySubscr data={data} availablePlans={availablePlans} />
      {/* <!--start overlay--> */}
      <MainFooter />
    </div>
  );
};

export default MySubscription;
